import { useState } from "react";

export default function BtnCopy({ value }) {
  let [ copied, setCopied ] = useState(false);

  const copyToClipboard = (content) => {
    const el = document.createElement('textarea');
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopied(true)
  };

  return (
    <button className={`btn-copy ${copied ? "copied" : ""}`} onClick={() => copyToClipboard(value)}>{copied ? "tersalin" : "salin"}</button>
  )
}