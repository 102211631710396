import { convertToRupiah } from "../../tools/numerictools";

export default function GeneratedQRIS({ generated }) {
  return (
    <div className="box-center">
      <div className="alert-payment">
        <span className="note-payment">
          Segera Lakukan Pembayaran Sebelum:
        </span>
        <span className="payment-date">
          {generated.valid_datetime} WIB
        </span>
      </div>
      <div className="payment-qris-info">
        <div className="qris-wrapper">
          <img src={generated.payment_code} alt="Kode Pembayaran QRIS" />
        </div>
      </div>
      <div className="payment-account-info">
        <span className="title">
          Jumlah yang harus dibayar
        </span>
        <div className="amount-info">
          <span className="number">Rp{convertToRupiah(generated.amount)}</span>
        </div>
      </div>
      <div className="payment-disclaimer">
        <h6>Pembayaran dengan mengunggah QRIS:</h6>
        <ol>
          <li>Unduh atau simpan QRIS di galeri</li>
          <li>Buka <b>m-banking</b> atau <b>e-wallet</b> yang akan anda digunakan</li>
          <li>Klik bayar atau QRIS</li>
          <li>Pilih menu unggah atau klik gambar galeri</li>
          <li>Pilih gambar QRIS yang sudah disimpan</li>
          <li>Apabila sudah berhasil mengunggah gambar QRIS, maka akan muncul nominal tagihan yang perlu anda bayarkan.</li>
          <li>Selesaikan pembayaran anda.</li>
        </ol>
      </div>
    </div>
  )
}