export function WaitAMomentInsideBox() {
  return (
    <div className="loading">
        <div className="loading-text">
          <div className="spinner-border text-secondary mr-3" role="status">
            <span className="sr-only">Tunggu Sebentar ...</span>
          </div>
          Tunggu Sebentar ...
        </div>
      </div>
  )
}