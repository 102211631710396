export const BANK_LIST = [
  {
    code: "BMRI",
    name: "Bank Mandiri",
    logo: "logo-mandiri.png",
  },
  {
    code: "IBBK",
    name: "Bank Maybank Indonesia",
    logo: "logo-maybank.png",
  },
  {
    code: "BBBA",
    name: "Bank Permata",
    logo: "logo-permata.png",
  },
  {
    code: "BBBB",
    name: "Bank Permata Syariah",
    logo: "logo-permata-syariah.png",
  },
  {
    code: "BNIN",
    name: "Bank Negara Indonesia",
    logo: "logo-bni.png",
  },
  {
    code: "HNBN",
    name: "Bank KEB Hana Indonesia",
    logo: "logo-keb-hana.png",
  },
  {
    code: "BRIN",
    name: "Bank Rakyat Indonesia",
    logo: "logo-bri.png",
  },
  {
    code: "BNIA",
    name: "Bank CIMB Niaga",
    logo: "logo-cimb.png",
  },
  {
    code: "BDIN",
    name: "Bank Danamon Indonesia",
    logo: "logo-danamon.png",
  },
  {
    code: "PDJB",
    name: "Bank BJB",
    logo: "logo-bjb.png",
  },
];

export const newBankList = [
  {
    code: "BRI",
    name: "Bank Rakyat Indonesia",
    logo: "logo-bri.png",
    switch_code: "va-bri",
  },
  {
    code: "BNI",
    name: "Bank Negara Indonesia",
    logo: "logo-bni.png",
    switch_code: "va-bni",
  },
  {
    code: "MANDIRI",
    name: "Bank Mandiri",
    logo: "logo-mandiri.png",
    switch_code: "va-mandiri",
  },
  {
    code: "PERMATA",
    name: "Bank Permata",
    logo: "logo-permata.png",
    switch_code: "va-permata",
  },
  {
    code: "BSI",
    name: "Bank Syariah Indonesia",
    logo: "logo-bsi.png",
    switch_code: "va-bsi",
  },
  {
    code: "MUAMALAT",
    name: "Bank Muamalat",
    logo: "logo-muamalat.jpg",
    switch_code: "va-muamalat",
  },
  {
    code: "CIMB",
    name: "Bank CIMB NIAGA",
    logo: "logo-cimb.png",
    switch_code: "va-cimb",
  },
  {
    code: "SINARMAS",
    name: "Bank Sinarmas",
    logo: "logo-sinarmas.png",
    switch_code: "va-sinarmas",
  },
  {
    code: "BNC",
    name: "Bank Neo Commerce",
    logo: "logo-bnc.png",
    switch_code: "va-neo-commerce",
  },
];
