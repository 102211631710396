import viberlink_logo from '../assets/images/viberlink-black.png';
import { useState } from 'react'
import { useCheckRegistrationHooks } from '../utils/CheckRegistrationHooks';
import moment from 'moment';

function CheckRegisPage() {
  const [ registration_number, setRegistrationNumber ] = useState();
  const  registrationCheck = useCheckRegistrationHooks()

  const onSubmitCheck = (event) => {
    event.preventDefault();
    registrationCheck.getData(registration_number);
  }

  return (
    <div className="page-content check-registration">
      <img src={viberlink_logo} className="mb-3" alt="" srcset="" width="300" />
      <div className="form-v4-content" style={{display: 'block'}}>
        <form className="form-detail" onSubmit={event => onSubmitCheck(event)}>
          <h2 className="text-center">Cek Status Pendaftaran</h2>
          <div className="row">
            <div className="col col-md-8">
              <label htmlFor="registration_number">Nomor Pendaftaran</label>
              <input type="text" name="registration_number" id="registration_number" className="input-text" value={registration_number} onChange={event => setRegistrationNumber(event.target.value)} />
            </div>
            <div className="col-auto">
              <div className="form-row-last pt-4">
                <button type="submit" name="register" style={{ height: '47px' }} className="btn register btn-primary btn-block">Cek Registrasi</button>
              </div>
            </div>
          </div>
        </form>
        <div className="detail-registration">
          <div className="row">
            {registrationCheck.success && 
              <div className="col-12">
                <div className="alert alert-success">
                  Terima kasih atas kepercayaan anda menggunakan jasa layanan dari Viberlink. Status registrasi Anda dengan nomor <b>{registrationCheck.registrationNumber}</b> adalah <b>{registrationCheck.data[0].status.display_name} ({registrationCheck.data[0].status.description})</b>
                </div>
                <div className="overflow-auto">
                  <table className="table" style={{whiteSpace: 'nowrap'}}>
                    <thead>
                      <tr>
                        <th>Tgl Waktu</th>
                        <th>Status</th>
                        <th>Catatan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {registrationCheck.data.map((data, index) => (
                        <tr key={index}>
                          <td>{moment.unix(data.createdAt).format('DD MMMM YYYY HH:mm')}</td>
                          <td>{data.status.display_name}</td>
                          <td>{data.description || '-'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            }

            {registrationCheck.loading && 
              <div className="col-12">
                <div className="alert alert-warning">
                  Harap menunggu ...
                </div>
              </div>
            }

            {registrationCheck.error && 
              <div className="col-12">
                <div className="alert alert-danger">
                  {registrationCheck.error}
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckRegisPage;