import { useState } from "react";
import { checkStatusRegister } from "../api/base";
import { checkBillingByRegNumber } from "../api/billing";

export function useCheckRegistrationHooks() {
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const getData = async (id) => {
    setLoading(true);
    setSuccess(false);
    setError("");
    setRegistrationNumber("");
    try {
      let { data } = await checkStatusRegister(id);
      setData(data.data);
      setRegistrationNumber(id);
      setSuccess(true);
    } catch (error) {
      let message = "Terjadi kesalahan";

      if (error.response) {
        message = error.response.data.message;
      }
      setError(message);
    }

    setLoading(false);
  };

  return {
    registrationNumber,
    data,
    loading,
    success,
    error,
    getData,
  };
}

export function useCheckBillingHooks() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const getData = async (filter) => {
    setLoading(true);
    setSuccess(false);
    setError("");
    try {
      let { data } = await checkBillingByRegNumber(filter);
      setData(data.data);
      setSuccess(true);
    } catch (error) {
      let message = "Terjadi kesalahan";

      if (error.response) {
        message = error.response.data.message;
      }
      setError(message);
    }

    setLoading(false);
  };

  return {
    data,
    loading,
    success,
    error,
    getData,
  };
}
