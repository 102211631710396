import { useEffect, useState } from "react";
import { getListPackage } from "../api/base";

export function useInternetPackageHooks() {
  const [ internetPackage, setInternetPackage ] = useState([]);
  const [ errorMsg, setErrorMsg ] = useState("");
  
  const fetchInternetPackage = async (filter = {sort_by: 'alphabet'}) => {
    try {
      let { data } = await getListPackage(filter);
      setInternetPackage(data.data)
    } catch (error) {
      setErrorMsg("Something wrong happen")
    }
  }
  
  return [ internetPackage, errorMsg, fetchInternetPackage ];
}