import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  generateAlfamartWithToken,
  generatePaymentWithToken,
} from "../api/billing";
import "../assets/styles/billing-styles.css";
import GeneratedVaBank from "../components/billing/GeneratedVaBank";
import { WaitAMomentInsideBox } from "../components/Loading";
import { convertToRupiah } from "../tools/numerictools";
import {
  useBillingTokenPayment,
  usePaymentPreset,
} from "../utils/BillingUtils";
import { newBankList } from "../tools/BankVariable";
import GeneratedQRIS from "../components/billing/GeneratedQRIS";
import GeneratedMinimart from "../components/billing/GeneratedMinimart";

const base_assets = process.env.REACT_APP_ASSETS_URL;

export default function BillingPaymentPage() {
  const {
    data: billingData,
    getData,
    loading: billingLoading,
    error,
  } = useBillingTokenPayment();

  const { paymentPresets } = usePaymentPreset();
  let [successGenerate, setSuccessGenerate] = useState(false);
  let [paymentResponse, setPaymentResponse] = useState({
    type: "",
    payment_response: {},
  });

  let [loading, setLoading] = useState(false);
  let { number, token } = useParams();
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  useEffect(() => {
    getData(number, token);
  }, [getData, number, token]);

  const onSafePrompt = () => {
    // Perform save operation here
    window.addEventListener("beforeunload", handleBeforeUnload);
  };

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  const generateVA = async (code) => {
    setLoading(true);
    try {
      let { data } = await generatePaymentWithToken(
        billingData.id,
        "va",
        token,
        { code: code }
      );
      setPaymentResponse({ type: "vaBank", payment_response: data.data });
      setSuccessGenerate(true);
      onSafePrompt();
    } catch (error) {
      let message = "Terjadi permasalahan";

      if (error.response) {
        message = error.response.data.message;
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
    scrollToTop();
  };

  const generateAlfamart = async () => {
    setLoading(true);
    try {
      let { data } = await generateAlfamartWithToken(billingData.id, token);
      setPaymentResponse({ type: "alfamart", payment_response: data.data });
      setSuccessGenerate(true);
      onSafePrompt();
    } catch (error) {
      let message = "Terjadi permasalahan";

      if (error.response) {
        message = error.response.data.message;
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
    scrollToTop();
  };

  const generateIndomaret = async () => {
    setLoading(true);
    try {
      let { data } = await generatePaymentWithToken(
        billingData.id,
        "indomaret",
        token
      );
      setPaymentResponse({ type: "indomaret", payment_response: data.data });
      setSuccessGenerate(true);
      onSafePrompt();
    } catch (error) {
      let message = "Terjadi permasalahan";

      if (error.response) {
        message = error.response.data.message;
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
    scrollToTop();
  };

  const generateQris = async () => {
    setLoading(true);
    try {
      let { data } = await generatePaymentWithToken(
        billingData.id,
        "qris",
        token
      );
      setPaymentResponse({ type: "qris", payment_response: data.data });
      setSuccessGenerate(true);
      onSafePrompt();
    } catch (error) {
      let message = "Terjadi permasalahan";

      if (error.response) {
        message = error.response.data.message;
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
    scrollToTop();
  };

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className="billing-page">
      {!successGenerate && (
        <div className="box-center">
          {(billingLoading || loading) && <WaitAMomentInsideBox />}
          <div className="payment-page-header">
            <img
              src={`${base_assets}/viberlink-icon-black-md.png`}
              alt="Logo Viberlink"
            />
            {billingData.status !== "PAID" && (
              <div className="billing-info">
                <table>
                  <tbody>
                    <tr>
                      <td>No. Reg</td>
                      <td>{billingData.registration_number || "-"}</td>
                    </tr>
                    <tr>
                      <td>Nama</td>
                      <td>{billingData.name || "-"}</td>
                    </tr>
                    <tr>
                      <td>Alamat</td>
                      <td>
                        {billingData.installation_address
                          ? `
                                ${billingData.installation_address?.address} RT ${billingData.installation_address?.rt}/ RW ${billingData.installation_address?.rw}, 
                                ${billingData.installation_address?.addresses.kelurahan}, 
                                ${billingData.installation_address?.addresses.kecamatan}, 
                                ${billingData.installation_address?.addresses.kota_kab}, 
                                ${billingData.installation_address?.addresses.province}, 
                                ${billingData.installation_address?.addresses.postal_code}
                                `
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td>Paket</td>
                      <td>{billingData.internet_package?.name || "-"}</td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td>
                        Rp {convertToRupiah(billingData.final_amount || 0)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          {error && (
            <div className="alert alert-danger text-center">
              <h5>Tagihan Tidak Ada</h5>
              {error}
            </div>
          )}
          {billingData.status === "PAID" && (
            <div className="alert alert-success text-center">
              <h5>Tagihan anda sudah dibayar</h5>
              Tagihan sudah dibayar. Anda tidak perlu melakukan pembayaran
              tagihan ini.
            </div>
          )}
          {billingData.status === "UNPAID" && !billingData.partner_payment && (
            <>
              <div className="d-flex flex-column justify-content-center text-center">
                <h3>Metode Pembayaran</h3>
                <p>Silahkan pilih salah satu metode berikut ini:</p>
                {alert.show && (
                  <div
                    className={`alert alert-${alert.type} alert-dismissible fade show`}
                    role="alert"
                  >
                    {alert.message}
                    <button
                      type="button"
                      className="close"
                      onClick={() =>
                        setAlert({ show: false, message: "", type: "" })
                      }
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )}
              </div>
              <div className="payment-list">
                <h6>QRIS & E-Wallet</h6>
                <PaymentMethodOption
                  payment_method={paymentPresets["qris"]}
                  img={`${base_assets}/logo-qris.png`}
                  name="QRIS"
                  actionPay={() => generateQris()}
                />
              </div>
              <div className="payment-list">
                <h6>Minimarket</h6>
                <PaymentMethodOption
                  payment_method={paymentPresets["alfamart"]}
                  img={`${base_assets}/logo-alfamart.png`}
                  name="Alfamart"
                  actionPay={() => generateAlfamart()}
                />
                <PaymentMethodOption
                  payment_method={paymentPresets["indomaret"]}
                  img={`${base_assets}/logo-indomaret.png`}
                  name="Indomaret"
                  actionPay={() => generateIndomaret()}
                />
              </div>
              <div className="payment-list">
                {/* VA Bank */}
                <h6>Virtual Account</h6>
                <PaymentMethodOption
                  payment_method={paymentPresets["va-bca"]}
                  img={`${base_assets}/logo-bca.png`}
                  name="Bank BCA"
                  actionPay={() => generateVA("BCA")}
                />
                {newBankList.map((bank, index) => (
                  <PaymentMethodOption
                    key={index}
                    payment_method={paymentPresets[bank.switch_code]}
                    img={`${base_assets}/${bank.logo}`}
                    name={bank.name}
                    actionPay={() => generateVA(bank.code)}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      )}
      {successGenerate && paymentResponse.type === "vaBank" && (
        <GeneratedVaBank
          generatedVA={paymentResponse.payment_response}
          presets={paymentPresets}
        />
      )}
      {successGenerate && paymentResponse.type === "alfamart" && (
        <GeneratedMinimart
          brand={"Alfamart"}
          brand_code={"alfamart"}
          generated={paymentResponse.payment_response}
          presets={paymentPresets}
        />
      )}
      {successGenerate && paymentResponse.type === "indomaret" && (
        <GeneratedMinimart
          brand={"Indomaret"}
          brand_code={"indomaret"}
          generated={paymentResponse.payment_response}
          presets={paymentPresets}
        />
      )}
      {successGenerate && paymentResponse.type === "qris" && (
        <GeneratedQRIS generated={paymentResponse.payment_response} />
      )}
    </div>
  );
}

function PaymentMethodOption({ payment_method, img, name, actionPay }) {
  const onClickPay = () => {
    if (payment_method) {
      actionPay();
    }
  };

  return (
    <div
      className={`payment-item ${
        payment_method === undefined && "unavailable"
      }`}
      onClick={() => onClickPay()}
    >
      <img src={img} alt={`Logo ${name}`} />
      <div className="d-flex flex-column text-right" style={{ gap: "10px" }}>
        <span className="bank-name">
          {name} {payment_method === undefined && "(Tidak tersedia)"}
        </span>
        {payment_method && (
          <span className="payment-fee">
            Biaya Layanan: Rp {convertToRupiah(payment_method?.fee || 0)}
          </span>
        )}
      </div>
    </div>
  );
}
