import axios from 'axios';
export const base_api = process.env.REACT_APP_API_URL;

export const apiBasic = axios.create({
  baseURL: base_api,
  timeout: 600000
});

export function getListPackage(params) {
  let api = apiBasic;
  return api.get("v1/presets/internet-packages", {params})
}

export function getRegions(params) {
  let api = apiBasic;
  return api.get("v1/presets/address", { params })
}

export function getPresetsPayment() {
  let api = apiBasic;
  return api.get("v1/presets/payment-method")
}

export function registerCostumer(data, params) {
  let api = apiBasic;
  return api.post('v1/registrations', data, { params });
}

export function checkStatusRegister(id) {
  let api = apiBasic;
  return api.get(`v1/registrations/tracking/${id}`);
}