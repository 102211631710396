import { useEffect, useState } from "react";
import { getRegions } from "../api/base";

export default function useRegionHooks() {
  const [address, setAddress] = useState('');
  const [rt, setRt] = useState('000');
  const [rw, setRw] = useState('000');
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [addresslist, setAddresslist] = useState([]);
  
  const [province, setProvince] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [addressId, setAddressId] = useState('');
  
  const getProvinces = async () => {
    try {
      let { data } =  await getRegions({ type: 'province'})
      setProvinces(data.data)
      setCity("")
      setDistrict("")
    } catch (error) {
      
    }
  }

  const resetRegion = () => {
    setCities([])
    setDistricts([])
    setAddresslist([])

    setAddress("")
    setProvince("")
    setCity("")
    setDistrict("")
    setAddressId("")
  }

  useEffect(() => {
    getProvinces()
  }, [])

  useEffect(() => {
    const getCities = async () => {
      try {
        let { data } =  await getRegions({p: province, type: 'kota_kab', parent_type: "province", parent_name: province})
        setCities(data.data)
      } catch (error) {
        
      }
    }

    setCity("")
    setDistrict("")
    setAddressId("")

    if (province) {
      getCities()
    }
  }, [province])

  useEffect(() => {
    const getDistricts = async () => {
      try {
        let { data } =  await getRegions({p: province, type: 'kecamatan', parent_type: "kota_kab", parent_name: city})
        setDistricts(data.data)
      } catch (error) {
        
      }
    }

    setDistrict("")
    setAddressId("")

    if (city) {
      getDistricts()
    }
  }, [city])

  useEffect(() => {
    const getAddressList = async () => {
      try {
        let { data } =  await getRegions({p: province, type: 'kelurahan', parent_type: "kecamatan", parent_name: district})
        setAddresslist(data.data)
      } catch (error) {
        
      }
    }

    setAddressId("")

    if (district) {
      getAddressList()
    }
  }, [district])

  return {
    address,
    rt,
    rw,
    provinces,
    cities,
    districts,
    addresslist,
    province,
    city,
    district,
    addressId,
    setAddress,
    setProvince,
    setCity,
    setDistrict,
    setAddressId,
    setRt,
    setRw,
    resetRegion
  }
}