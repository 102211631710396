import { useCallback, useEffect, useState } from "react";
import { getBillingByNumber, getBillingByNumberAndToken } from "../api/billing";
import { getPresetsPayment } from "../api/base";

export function useBillingPayment() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const getData = async (billing_number) => {
    setLoading(true);
    setSuccess(false);
    setError("");
    try {
      let { data } = await getBillingByNumber(billing_number);
      setData(data.data);
      setSuccess(true);
    } catch (error) {
      let message = "Terjadi kesalahan";

      if (error.response) {
        message = error.response.data.message;
      }
      setError(message);
    }

    setLoading(false);
  };

  return {
    data,
    loading,
    success,
    error,
    getData,
  };
}

export function useBillingTokenPayment() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const getData = useCallback(async (billing_number, token) => {
    setLoading(true);
    setSuccess(false);
    setError("");
    try {
      let { data } = await getBillingByNumberAndToken(billing_number, token);
      setData(data.data);
      setSuccess(true);
    } catch (error) {
      let message = "Terjadi kesalahan";

      if (error.response) {
        message = error.response.data.message;
      }
      setError(message);
    }

    setLoading(false);
  }, []);

  return {
    data,
    loading,
    success,
    error,
    getData,
  };
}

export function usePaymentPreset() {
  const [paymentPresets, setPaymentPresets] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const getData = async () => {
    setLoading(true);
    setSuccess(false);
    setError("");
    try {
      let { data } = await getPresetsPayment();

      let objPresets = {};
      data.data.forEach((pay_method) => {
        objPresets[pay_method.name] = pay_method;
      });

      setPaymentPresets(objPresets);
      setSuccess(true);
    } catch (error) {
      let message = "Terjadi kesalahan";

      if (error.response) {
        message = error.response.data.message;
      }
      setError(message);
    }

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    paymentPresets,
    loading,
    success,
    error,
    getData,
  };
}
